import { useState } from 'react';
import dynamic from 'next/dynamic';

import { Box, Top } from './style';
import { IMainNavigation } from '../common';
import { IImage } from '../common';


const Loader = dynamic(() => import('../../components/Loader'), {
  loading: () => <p>....Loading.....</p>,
});

const SearchField = dynamic(() => import('../SearchField'), {
  loading: () => <Loader isLoading />,
});

const Logo = dynamic(() => import('../Logo'), {
  loading: () => <Loader isLoading />,
});

const Menu = dynamic(() => import('./menu').then((mod)=>mod.Menu), {
  loading: () => <Loader isLoading />,
});

const OverlayNav = dynamic(() => import('./overlayNav').then((mod)=>mod.OverlayNav), {
  loading: () => <Loader isLoading />,
});

export interface ISearch {
  query: string;
}
export interface IHeaderData {
  navigationNodes: IMainNavigation[];
}

export const Header = () => {
  const [isOverlayShown, setIsOverlayShown] = useState(false);

  const data: IImage = {
    url: '/images/ahs-logo.png',
    title: 'AHS',
    width:300,
    height:58,
  };

  const HideOverlay = () => {
    setIsOverlayShown(!isOverlayShown);
  };

  return (
    <>
      <OverlayNav onMouseOver={HideOverlay} isShown={isOverlayShown} />
      <Box>
        <Top onMouseOver={HideOverlay}>
          <Logo {...data} />
          <SearchField showField={false} search="" closeMenu={setIsOverlayShown} />
        </Top>

        <Menu handleOverlay={setIsOverlayShown} hideMenu={isOverlayShown === false} />
      </Box>
    </>
  );
};
